import { Injectable } from '@angular/core';
import { TopbarItem } from '../types/types';

@Injectable()
export class ContactsItemsService{
    private contactItems = [
        {
            label:'Компании',
            routerLink:'/contacts/companies'
        },
        {
            label:'Менеджеры',
            routerLink:'/contacts/managers'
        },
        {
            label: 'Департаменты',
            routerLink: '/contacts/departments'
        },
        {
            label:'Порядчики',
            routerLink:'/contacts/subcontractor'
        }
    ]

    public getItems(){
        return this.contactItems;
    }


}