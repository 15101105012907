import { Injectable } from '@angular/core';
import { TopbarItem } from '../types/types';

@Injectable()
export class TopbarItemsService{
    private topbarItems:Array<TopbarItem> = [
        {
            label:'Проекты',
            imag:'prod.svg',
            routerLink:'/projects'
        },
        {
            label:'Информация',
            imag:'info.svg',
            routerLink:'/contacts'
        },
        {
            label:'Задачи',
            imag:'task.svg',
            routerLink:'/task'
        },
        {
            label:'Статистика',
            imag:'stat.svg',
            routerLink:'/statistics'
        }
    ]

    public getItems(){
        return this.topbarItems;
    }


}